<template>
  <div class="case-type-description">
    <div v-if="period == '5 Day Notice' || period == 'Immediate Demand'">
      <a v-bind:href="file1" target="_blank">Sample Notice</a> <b>or </b>
      <a v-bind:href="file2" target="_blank">Blank Notice</a>
    </div>
    <div
      v-if="period == '10 Day Notice'"
      style="
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        font-family: 'Inter', sans-serif;
      "
    >
      Sample Notice <b>or</b> Blank Notice (Not Available)
    </div>
    <div v-if="period == '60 Day or 120 Day Notice'">
      <a v-bind:href="file1" target="_blank">Sample Notice</a> <b>or </b>
      <a v-bind:href="file2" target="_blank">Blank Notice (60)</a> <b>or </b>
      <a v-bind:href="file3" target="_blank">Blank Notice (120)</a>
    </div>
    <br />
    <div style="text-align: left; font-weight: bold; font-size: 12px">
      <span>TIP: </span><br />
      <div :key="index" style="margin-left: 10px" v-for="(item, index) in tips">
        {{ item }}<br />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CaseTypeDescription',
    props: {
      period: String,
      caseTypeDescription: String,
      file1: String,
      file2: String,
      file3: String,
      tips: Array,
    },
  }
</script>

<style scoped>
  div.case-type-description {
    background-color: #d9d9d9;
    width: 505px;
    padding: 10px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: left;
  }

  div.case-type-description p,
  div.case-type-description a {
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin: 0 0 10px;
  }

  div.case-type-description p span {
    font-weight: 800;
  }

  div.case-type-description a {
    /*display: block;*/
    color: #0047ff;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0;
    font-weight: bold;
  }
</style>
