<template>
  <div class="case-types">
    <CaseTypeModule
      :key="index"
      v-for="(item, index) in caseTypes"
      :caseType="item"
      :customer="customer"
    />
  </div>
</template>

<script>
  import CaseTypeModule from '@/components/molecules/CaseTypeModule'

  export default {
    name: 'Home',
    components: {
      CaseTypeModule,
    },
    methods: {},
    async beforeCreate() {},
    data() {
      return {
        customer: location.pathname.split('/')[1],
        caseTypes: [
          {
            label: `Non-payment`,
            period: '5 Day Notice',
            tips: [
              'IF YOU RECEIVED PARTIAL PAYMENT AFTER SERVING THE NOTICE.',
              "(1) RETURN THE PAYMENT AND WE'LL FILE BASED ON THIS NOTICE; OR",
              '(2) KEEP THE PAYMENT & SERVE A NEW NOTICE.',
            ],
            file1:
              'https://storage.googleapis.com/halsted-dev-samples/5%20Day_Example.pdf',
            file2:
              'https://storage.googleapis.com/halsted-dev-samples/5%20Day_Blank%20Copy.docx',
            file3: '',
          },
          {
            label: `Lease Violation`,
            period: '10 Day Notice',
            tips: [
              "EMAIL OUR LAW FIRM THE LEASE, DATES & DETAILS OF THE VIOLATIONS AND WE'LL DRAW UP THIS NOTICE FOR YOU.",
            ],
            file1: '',
            file2: '',
            file3: '',
          },
          {
            label: `Squatter`,
            period: 'Immediate Demand',
            tips: [
              'IF THEY DONT GIVE YOU THEIR NAMES, YOU CAN LEAVE THAT SECTION OF THE NOTICE BLANK.',
            ],
            file1:
              'https://storage.googleapis.com/halsted-dev-samples/Immediate%20Demand_Example.pdf',
            file2:
              'https://storage.googleapis.com/halsted-dev-samples/Immediate%20Demand_Blank%20Copy.docx',
            file3: '',
          },
          {
            label: `Non Renewal`,
            period: '60 Day or 120 Day Notice',
            tips: [
              "IF THEY'VE LIVED THERE UNDER 3 YEARS, USE 60 DAY.",
              "IF THEY'VE LIVED THERE OVER 3 YEARS, USE 120 DAY.",
              'THE TERMINATION DATE ON THE NOTICE MUST BE THE LAST DAY OF',
              'THE MONTH IT EXPIRES. EXAMPLE: IF YOU SERVE ON 1/10, IT EXPIRES 3/31.',
            ],
            file1:
              'https://storage.googleapis.com/halsted-dev-samples/60%20Day_Example.pdf',
            file2:
              'https://storage.googleapis.com/halsted-dev-samples/60%20Day_Blank%20Copy.docx',
            file3:
              'https://storage.googleapis.com/halsted-dev-samples/120%20Day_Blank%20Copy.docx',
          },
        ],
      }
    },
  }
</script>

<style scoped>
  div.case-types {
    margin: 20px 0 50px;
  }
</style>
