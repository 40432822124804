<template>
  <div>
    <el-button type="primary" class="case-type-button">
      <a :href="`/${this.customerSlugify}/${this.labelSlugify}`"
        >{{ period }}<br /><br />({{ label }})</a
      >
    </el-button>
  </div>
</template>

<script>
  export default {
    name: 'CaseTypeButton',
    props: {
      label: String,
      period: String,
      customer: String,
    },
    data() {
      return {
        customerSlugify: this.customer.replace(' ', '-').toLowerCase(),
        labelSlugify: this.label.replace(' ', '-').toLowerCase(),
      }
    },
  }
</script>

<style scoped>
  .case-type-button {
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: none;
    height: 60px;
    width: 180px;
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    margin-right: 25px;
  }

  a {
    text-decoration: none;
    display: flex;
    height: 60px;
    width: 150px;
    align-items: center;
    justify-content: center;
  }
</style>
