<template>
  <div class="case">
    <CaseTypeButton
      :customer="customer"
      :label="caseType.label"
      :period="caseType.period"
    />
    <CaseTypeDescription
      :caseTypeDescription="caseType.description"
      :period="caseType.period"
      :file1="caseType.file1"
      :file2="caseType.file2"
      :file3="caseType.file3"
      :tips="caseType.tips"
    />
  </div>
</template>

<script>
  import CaseTypeButton from '@/components/atoms/CaseTypeButton'
  import CaseTypeDescription from '@/components/atoms/CaseTypeDescription'

  export default {
    name: 'CaseTypeModule',
    components: {
      CaseTypeButton,
      CaseTypeDescription,
    },
    props: {
      caseType: Object,
      customer: String,
    },
  }
</script>

<style scoped>
  div.case {
    display: flex;
    margin: 0 auto 40px;
    max-width: 700px;
  }
</style>
